<template>
  <div class="main">
    <div class="main_box">
      <div class="flex_row_center main_description">
        高质量服务于：
        <div class="main_description_item" v-for="(item, index) in descriptionList" :key="item.value">
          <span>{{item.name}}</span>
        </div>
      </div>
      <div class="main_scheme">
        <h1 class="main_scheme_title">解决方案</h1>
        <div class="flex main_scheme_list" @mouseleave="handleMouseLeave">
          <div class="flex_column_center main_scheme_list_item" v-for="(item, index) in schemeList" :key="item.value" @mouseenter="handleMouseEnter(item.value)">
            <div class="main_scheme_list_item_icon">
              <el-image :src="item.iconOn" v-show="item.value === schemeItem"></el-image>
              <el-image :src="item.iconOff" v-show="item.value !== schemeItem"></el-image>
            </div>
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="main_plate">
        <div class="flex main_plate_list">
          <div class="flex_column main_plate_list_item" v-for="(item, index) in plateList" :key="index">
            <div class="main_plate_list_item_link"></div>
            <h2 class="main_plate_list_item_h2">{{item.main}}</h2>
            <h3 class="main_plate_list_item_h3">{{item.vice}}</h3>
            <div class="flex_row_center main_plate_list_item_btn">
              <span>{{item.btn}}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "management",
    data() {
      return {
        descriptionList: [
          {name: '全产业生态', value: 0},
          {name: '全顺序过程', value: 1},
          {name: '全参与方', value: 2},
          {name: '全在线管理', value: 3}
        ],
        schemeList: [
          {name: '招投标智能分析管理体系', iconOn: `${require('@/assets/images/icon/icon_2_on.png')}`, iconOff: `${require('@/assets/images/icon/icon_2_off.png')}`, value: 0},
          {name: '工程建设数字化管理体系', iconOn: `${require('@/assets/images/icon/icon_3_on.png')}`, iconOff: `${require('@/assets/images/icon/icon_3_off.png')}`, value: 1},
          {name: '产业供应链金融生态体系', iconOn: `${require('@/assets/images/icon/icon_4_on.png')}`, iconOff: `${require('@/assets/images/icon/icon_4_off.png')}`, value: 2},
          {name: '建筑业用户增值服务体系', iconOn: `${require('@/assets/images/icon/icon_5_on.png')}`, iconOff: `${require('@/assets/images/icon/icon_5_off.png')}`, value: 3}
        ],
        schemeItem: 0,
        plateList: [
          {main: '资质查询', vice: '资质业绩信用查询系统', btn: '查看详情', value: 0},
          {main: '招标投标', vice: '招投标智能分析管理系统', btn: '查看详情', value: 1},
          {main: '专属项目', vice: '分包项目居间服务平台', btn: '查看详情', value: 2},
          {main: '造价咨询', vice: '工程造价信息服务平台', btn: '查看详情', value: 3},
          {main: '融资申请', vice: '中小企业融资服务申请', btn: '查看详情', value: 4}
        ]
      }
    },
    mounted() {},
    methods: {
      handleMouseEnter(val) {
        this.schemeItem = val
        switch (val) {
          case 0:
            this.plateList = [
              {main: '资质查询', vice: '资质业绩信用查询系统', btn: '查看详情', value: 0},
              {main: '招标投标', vice: '招投标智能分析管理系统', btn: '查看详情', value: 1},
              {main: '专属项目', vice: '分包项目居间服务平台', btn: '查看详情', value: 2},
              {main: '造价咨询', vice: '工程造价信息服务平台', btn: '查看详情', value: 3},
              {main: '融资申请', vice: '中小企业融资服务申请', btn: '查看详情', value: 4}
            ]
            break;
          case 1:
            this.plateList = [
              {main: '智慧工地', vice: '施工现场智能管理系统', btn: '查看详情', value: 0},
              {main: '水印相机', vice: '招投标智能分析管理系统', btn: '查看详情', value: 1},
              {main: '机械管理', vice: '机械设备智能管理系统', btn: '查看详情', value: 2},
              {main: '智能询价', vice: '精准查询建筑材料信息价格', btn: '查看详情', value: 3},
              {main: '保函申请', vice: '工程保函在线申请系统', btn: '查看详情', value: 4}
            ]
            break;
          case 2:
            this.plateList = [
              {main: '装修服务', vice: '全屋装修精准服务平台', btn: '查看详情', value: 0},
              {main: '工地招工', vice: '建筑工人精准服务平台', btn: '查看详情', value: 1},
              {main: '供应商', vice: '精准查询附近材料商家', btn: '查看详情', value: 2},
              {main: '二手租售', vice: '二手设备买卖租售平台', btn: '查看详情', value: 3},
              {main: '物流车队', vice: '精准对接附近运输车队', btn: '查看详情', value: 4}
            ]
            break;
          case 3:
            this.plateList = [
              {main: '专家问诊', vice: '评委专家二次就业平台', btn: '查看详情', value: 0},
              {main: '代理服务', vice: '资质税务代理服务平台', btn: '查看详情', value: 1},
              {main: '入门培训', vice: '就业入门在线学习平台', btn: '查看详情', value: 2},
              {main: '建筑文库', vice: '建筑规范文库查询平台', btn: '查看详情', value: 3},
              {main: '社会团体', vice: '社会团体智能管理平台', btn: '查看详情', value: 4}
            ]
            break;
          default:
            break;
        }
      },
      handleMouseLeave() {
        // this.schemeItem = null
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    .main_box {
      padding-bottom: 80px;
      .main_description {
        height: 52px;
        background: linear-gradient(0deg, #D8EDFF 0%, #ECF6FF 100%);
        box-shadow: 0 3px 6px 1px rgba(3, 96, 230, .06);
        font-size: 20px;
        color: #1890FF;
        .main_description_item {
          padding: 0 20px;
        }
        .main_description_item:nth-child(1) {
          padding-left: 0;
        }
        .main_description_item:last-child {
          padding-right: 0;
        }
      }
      .main_scheme {
        padding-bottom: 40px;
        .main_scheme_title {
          padding: 40px 0;
          font-size: 30px;
          font-weight: 500;
          color: #4E4E4E;
        }
        .main_scheme_list {
          border-bottom: 1px solid #E2E5EC;
          .main_scheme_list_item {
            flex: 1;
            height: 120px;
            background-color: #fff;
            border-bottom: 2px solid rgba(255, 255, 255, 0);
            font-size: 20px;
            color: #4E4E4E;
            cursor: pointer;
            .main_scheme_list_item_icon {
              padding-bottom: 20px;
            }
          }
          .main_scheme_list_item:hover {
            background: rgba(34, 136, 252, .05);
            border-bottom: 2px solid #1890FF;
            color: #1890FF;
          }
        }
      }
      .main_plate {
        .main_plate_list {
          margin: -9px;
          .main_plate_list_item {
            flex: 1;
            align-items: flex-start;
            padding: 40px 20px;
            margin: 9px;
            border: 1px solid #E4E9F3;
            line-height: 1;
            text-align: left;
            cursor: pointer;
            .main_plate_list_item_link {
              width: 30px;
              height: 4px;
              margin-bottom: 20px;
              background-color: #2288FC;
            }
            .main_plate_list_item_h2 {
              padding-bottom: 30px;
              font-size: 20px;
              font-weight: 500;
              color: #333E4D;
            }
            .main_plate_list_item_h3 {
              padding-bottom: 30px;
              font-size: 16px;
              color: #333E4D;
            }
            .main_plate_list_item_btn {
              width: auto;
              height: 30px;
              padding: 0 8px;
              border: 1px solid #E2E5EC;
              font-size: 16px;
              color: #2288FC;
            }
          }
          .main_plate_list_item:hover {
            box-shadow: 3px 3px 12px 1px rgba(61, 145, 255, .12);
            .main_plate_list_item_btn {
              background-color: #1890FF;
              border: 1px solid #1890FF;
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>